import React from "react";

import SelFilter from "./selFilter.component";
import AuthService from "../services/auth.service";
import UserService from "../services/user.service";

import * as constants from '../common/constants'

import { addParamToUrl, formatdate, removeParamFromUrl } from "../common/functions";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import FormControl from '@mui/material/FormControl';

import {
    DataGrid, gridPageCountSelector, gridPageSelector, useGridApiContext, useGridSelector, GridToolbarQuickFilter
} from '@mui/x-data-grid';

import Pagination from '@mui/material/Pagination';

import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';

import { GrEdit, GrHistory, GrList } from 'react-icons/gr'

import RuleEditDialog from "./ruleEditDialog.component";
import RuleMNDialog from "./ruleMNDialog.component";

import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

import UpdateStatusMultiple from "./multiStatusUpdate.component";
import { RenderCellExpand, GridCellExpand } from "../common/renderCellExpand";

import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer-continued';

function Rules() {

    const headerDisplayOptions = { none: 'none', flex: 'flex' }
    const [hideHeaderText, setHideHeaderText] = React.useState(headerDisplayOptions.none);

    const { enqueueSnackbar } = useSnackbar();
    const [rowData, setRowData] = React.useState([]);
    const [selectedRowIDs, setSelectedRowIDs] = React.useState([]);
    const [selectedRowHistoryIDs, setSelectedRowHistoryIDs] = React.useState([]);
    const [rowDataHistory, setRowDataHistory] = React.useState([]);
    const [rowToEdit, setRowToEdit] = React.useState({});
    const [MNToEdit, setMNToEdit] = React.useState(null);
    const [openDialogEdit, setOpenDialogEdit] = React.useState(false);
    const [openDialogEditMultiple, setOpenDialogEditMultiple] = React.useState(false);
    const [openDialogEditMN, setOpenDialogEditMN] = React.useState(false);
    const [openDialogHistory, setOpenDialogHistory] = React.useState(false);
    const [openDialogCompare, setOpenDialogCompare] = React.useState(false);
    const [compareL, setCompareL] = React.useState({});
    const [compareR, setCompareR] = React.useState({});

    let navigate = useNavigate();

    const showEditDialog = (row) => {
        addParamToUrl('editRule', row.id);
        setRowToEdit(row);
        setOpenDialogEdit(true);

    }
    const showEditMNDialog = (row) => {
        addParamToUrl('editMN', row.id);
        setMNToEdit(row);
        setOpenDialogEditMN(true);

    }

    const handleNewRule = () => {
        setRowToEdit([]);
        setOpenDialogEdit(true);
    }

    const showHistoryDialog = (row) => {
        setRowDataHistory([]);
        addParamToUrl('showHistory', row.id);
        setRowToEdit(row);
        UserService.getRuleHistory(row.id).then(
            response => {
                setRowDataHistory(response.data);
            },
            error => {
                setRowData([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );

        setOpenDialogHistory(true);

    }

    const handleCloseEdit = (reload) => {
        removeParamFromUrl('editRule');
        setOpenDialogEdit(false);
        if (reload) {
            let tmpFilter = JSON.parse(localStorage.getItem('lastFilterRules'));
            UserService.getRulesFilter(tmpFilter).then(
                response => {
                    setRowData(response.data);

                },
                error => {
                    setRowData([]);
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                }
            )
        }
    };

    const handleCloseEditMN = (reload) => {
        setMNToEdit(null);
        removeParamFromUrl('editMN');
        setOpenDialogEditMN(false);
    };

    const handleCloseHistory = () => {
        removeParamFromUrl('showHistory');
        setOpenDialogHistory(false);
    };

    const columns = [
        // { field: 'id', headerName: 'Id' },
        { field: 'id', headerName: 'Id' },
        {
            field: 'project', headerName: 'Project', width: '90',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        {
            field: 'name', headerName: 'Name', width: '200',
            renderCell: (params) => (
                <GridCellExpand
                    value={params.value || ""}
                    width={params.colDef.computedWidth + 100}
                />
            )
        },
        {
            field: 'definition', headerName: 'Definition', width: '400',
            renderCell: RenderCellExpand
        },
        {
            field: 'status',
            headerName: 'Status',
            width: '110',
            renderCell: (params) => {
                let status = "";
                status = constants.STATUSES.find(x => x.id === params.row.status)?.val;
                return status;
            },
        },
        {
            field: 'manyToMany',
            headerName: 'M:N',
            width: '50',
            renderCell: (params) => {
                const onClickEditMN = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return showEditMNDialog(params.row);
                };

                return <span>
                    {params.row.manyToMany === 1 ?
                        <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickEditMN}><GrList /></IconButton>
                        : ""}
                </span>;
            },
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            width: '80',
            renderCell: (params) => {
                const onClickEdit = (e) => {
                    e.stopPropagation(); // don't select this row after clicking

                    return showEditDialog(params.row);
                };

                const onClickHistory = (e) => {
                    e.stopPropagation(); // don't select this row after clicking
                    return showHistoryDialog(params.row);
                };

                return <span>
                    <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickEdit}><GrEdit /></IconButton>
                    <IconButton sx={{ pt:0.1 }} size="small" onClick={onClickHistory}><GrHistory /></IconButton>
                </span>;
            },
        },
        {
            field: 'usedIn',
            headerName: 'Used in',
            width: '80',
            renderCell: (params) => {
                const showRulesInRest = (e) => {
                    e.stopPropagation();
                    navigate("/restructuring?idRule=" + params.row.id);
                }
                if (params.row.usedIn > 0) {
                    return <Box sx={{ p: 0, display: 'flex', borderTop: 0, width: '100%' }}>
                        <Box>
                            <Button sx={{}} style={{
                                margin: 0,
                                padding: 0,
                                maxWidth: 130,
                                justifyContent: "flex-start",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textAlign: "left",
                                display: "block"
                            }} size="small" onClick={showRulesInRest}>{params.row.usedIn + " field" + (params.row.usedIn > 1 ? "s" : "")}</Button>
                        </Box>
                    </Box >;
                }
                return "";
            }
        },
        {
            field: 'comment', headerName: 'Comment', width: '200', editable: true,
            renderCell: RenderCellExpand
        },
        {
            field: 'modifiedAt',
            headerName: 'Last change',
            width: '150',
            renderCell: (params) => {
                return formatdate(params.row.modifiedAt);
            }
        },
        { field: 'modfiedBy', headerName: 'User', width: '120' },

    ];

    const columnsHistory = [
        // { field: 'id', headerName: 'Id' },
        { field: 'id', headerName: 'Id' },
        { field: 'project', headerName: 'Project', width: '70' },
        { field: 'name', headerName: 'Name', width: '150' },
        {
            field: 'definition', headerName: 'Definition', width: '300',
            renderCell: RenderCellExpand
        },
        {
            field: 'status',
            headerName: 'Status',
            width: '110',
            renderCell: (params) => {
                let status = "";
                status = constants.STATUSES.find(x => x.id === params.row.status)?.val;
                return status;
            },
        },
        { field: 'manyToMany', headerName: 'M:N', width: '50' },
        {
            field: 'comment', headerName: 'Comment', width: '200', editable: true,
            renderCell: RenderCellExpand
        },
        {
            field: 'modifiedAt',
            headerName: 'Last change',
            width: '150',
            renderCell: (params) => {
                return formatdate(params.row.modifiedAt);
            }
        },
        { field: 'modfiedBy', headerName: 'User', width: '120' },

    ];

    const handleFilterUpdate = (value) => {
        localStorage.setItem('lastFilterRules', JSON.stringify(value));

        UserService.getRulesFilter(value).then(
            response => {
                setRowData(response.data);

                // show edit dialogs if param in url
                let url = new URL(window.location.href);
                let editRule = url.searchParams.get("editRule");
                if (editRule) {
                    let rule = response.data.find(x => x.id == editRule);
                    if (rule) {
                        showEditDialog(rule);
                    } else {
                        removeParamFromUrl('editRule');
                    }

                }
                let editMN = url.searchParams.get("editMN");
                if (editMN) {
                    let rule = response.data.find(x => x.id == editMN);
                    if (rule) {
                        showEditMNDialog(rule);
                    } else {
                        removeParamFromUrl('editMN');
                    }

                }
                let showHistory = url.searchParams.get("showHistory");
                if (showHistory) {
                    let rule = response.data.find(x => x.id == showHistory);
                    if (rule) {
                        showHistoryDialog(rule);
                    } else {
                        removeParamFromUrl('showHistory');
                    }

                }
            },
            error => {
                setRowData([]);
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    };


    function CustomFooterStatusComponent(props) {
        const apiRef = useGridApiContext();
        const page = useGridSelector(apiRef, gridPageSelector);
        const pageCount = useGridSelector(apiRef, gridPageCountSelector);

        return (

            <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
                <Box sx={{ m: 0, flex: 1 }}>
                    {selectedRowIDs.length > 0 && AuthService.hasPermission(constants.PERMISSION_EDIT) ?
                        <Button
                            sx={{ m: 0 }}
                            variant="outlined"
                            onClick={() => { setOpenDialogEditMultiple(true) }}
                        >Set status for {selectedRowIDs.length} selected rows</Button>
                        : ""}
                    <Button
                        sx={{ m: 0 }}
                        variant="outlined"
                        onClick={handleNewRule}
                    >Add new rule</Button>
                </Box>

                <Box >
                    <Pagination
                        sx={{ height: 37 }} /* to prevent changing footer height on selected rows */
                        color="primary"
                        count={pageCount}
                        page={page + 1}
                        onChange={(event, value) => apiRef.current.setPage(value - 1)}

                    />
                </Box>
            </Box>


        );
    }

    const HandleUpdateStatusMultiple = (status) => {
        setOpenDialogEditMultiple(false);

        let updateData = {
            "status": status,
            "ids": selectedRowIDs
        }
        UserService.updateRuleMultipleStatus(updateData).then(res => {
            //refresh after sucessfull update
            let tmpFilter = JSON.parse(localStorage.getItem('lastFilterRules'));
            UserService.getRulesFilter(tmpFilter).then(
                response => {
                    setRowData(response.data);
                    setSelectedRowIDs([]);
                    enqueueSnackbar('Successfully updated status', { variant: 'success' });
                },
                error => {
                    setRowData([]);
                    const resMessage =
                        (error.response.data.errorMessage) ||
                        error.message ||
                        error.toString();
                    enqueueSnackbar(resMessage, { variant: 'error' });
                }
            )
        },
            error => {
                const resMessage =
                    (error.response.data.errorMessage) ||
                    error.message ||
                    error.toString();
                enqueueSnackbar(resMessage, { variant: 'error' });
            }
        );
    }

    function QuickSearchToolbar() {
        return (
            <Box
                sx={{
                    p: 0.5,
                    pb: 0,
                }}
            >
                <GridToolbarQuickFilter />
            </Box>
        );
    }

    
function CustomFooterHistoryStatusComponent(props) {
    const apiRef = useGridApiContext();
    const page = useGridSelector(apiRef, gridPageSelector);
    const pageCount = useGridSelector(apiRef, gridPageCountSelector);

    // this seems thrown an error on console when opening the history - TODO: check why
    // setHideHeaderText(selectedRowHistoryIDs.length > 0 ? headerDisplayOptions.flex : headerDisplayOptions.none)

    return (

        <Box sx={{ p: 1, display: 'flex', borderTop: 1, borderColor: '#ddd' }}>
            <Box sx={{ m: 0, flex: 1 }}>

                {selectedRowHistoryIDs.length == 2 ?
                    <Button
                        sx={{ m: 0 }}
                        variant="outlined"
                        onClick={() => {
                            setOpenDialogCompare(true)
                            let sortedHistory = selectedRowHistoryIDs.sort()
                            if (sortedHistory.length == 2) {
                                // const getSelectedUser = (userId) => users.find(({id}) => id == userId)
                                const leftId = sortedHistory[0]
                                const getHistoryRecord = (leftId) => rowDataHistory.find(({ modifiedAt }) => modifiedAt == leftId)
                                let leftHist = getHistoryRecord(sortedHistory[0])
                                let rightHist = getHistoryRecord(sortedHistory[1])
                                setCompareL(leftHist);
                                setCompareR(rightHist);
                            } else {
                                setCompareL({});
                                setCompareR({});
                            }
                        }}
                    >Compare selected rows</Button>
                    : <Button
                        sx={{ m: 0 }}
                        variant="outlined"
                        disabled={true}
                    >Select 2 rows to compare</Button>}
            </Box>

            <Box >
                <Pagination
                    sx={{ height: 37 }} /* to prevent changing footer height on selected rows */
                    color="primary"
                    count={pageCount}
                    page={page + 1}
                    onChange={(event, value) => apiRef.current.setPage(value - 1)}

                />
            </Box>
        </Box>


    );
}

    return (
        <div style={{ height: '85vh', width: '100%' }}>
            <header className="">
                <h3>Rules</h3>
                {/* <h3>{this.state.content}</h3> */}
                <SelFilter
                    handleFilter={handleFilterUpdate}
                    useBlock={false}
                    useLayout={false}
                    useTable={false}
                />

            </header>
            <Box sx={{ height: '85vh', width: '100%' }}>
                <DataGrid
                    rows={rowData}
                    columns={columns}
                    columnVisibilityModel={{
                        id: false
                    }}
                    density="compact"
                    experimentalFeatures={{ newEditingApi: true }}
                    checkboxSelection
                    disableRowSelectionOnClick
                    rowHeight={32}
                    getRowClassName={(params) => `gridRowColor-${params.row.status}`}
                    pagination
                    pageSize={100}
                    pageSizeOptions={[100]}
                    slots={{
                        footer: CustomFooterStatusComponent,
                        toolbar: QuickSearchToolbar
                    }}
                    rowSelectionModel={selectedRowIDs}
                    onRowSelectionModelChange={(ids, details) => {
                        setSelectedRowIDs(ids);
                    }}
                />
            </Box>
            <RuleEditDialog
                openDialogEdit={openDialogEdit}
                rowToEdit={rowToEdit}
                onClose={handleCloseEdit}
            />

            <RuleMNDialog
                openDialogEdit={openDialogEditMN}
                rowToEdit={MNToEdit}
                onClose={handleCloseEditMN}
            />

            <Dialog
                open={openDialogHistory}
                onClose={handleCloseHistory}
                fullWidth={true}
                maxWidth="lg"
            >
                <DialogTitle>History {rowToEdit.fieldName} ({rowToEdit.id})</DialogTitle>
                <DialogContent>
                    <DialogContentText>

                    </DialogContentText>
                    <div style={{ height: '400px' }}>
                        <DataGrid
                            rows={rowDataHistory}
                            columns={columnsHistory}
                            columnVisibilityModel={{
                                id: false
                            }}
                            density="compact"
                            rowHeight={32}
                            getRowClassName={(params) => `gridRowColor-${params.row.status}`}
                            getRowId={(row) => row.modifiedAt}
                            checkboxSelection
                            slots={{
                                footer: CustomFooterHistoryStatusComponent,
                                toolbar: QuickSearchToolbar
                            }}
                            rowSelectionModel={selectedRowHistoryIDs}
                            onRowSelectionModelChange={(ids, details) => {
                                setSelectedRowHistoryIDs(ids);
                            }}
                            sx={{
                                '.MuiDataGrid-cell--editing select': { pl: 1, pb: 0, pt: 0 },
                                '.MuiDataGrid-cell--editing input': { p: 1 },
                                "& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer": {
                                    // display: "flex"
                                    display: headerDisplayOptions[hideHeaderText] ?? "flex"
                                }
                            }}
                        />
                    </div>
                    <FormControl>

                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseHistory}>Close</Button>
                </DialogActions>
            </Dialog>
            <Dialog
                open={openDialogCompare}
                onClose={() => { setOpenDialogCompare(false) }}
                maxWidth="false"
            >
                <DialogTitle>History compare</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    </DialogContentText>

                        <ReactDiffViewer
                            oldValue={compareL.definition ?? ""}
                            newValue={compareR.definition ?? ""}
                            leftTitle={"Definition - " + formatdate(compareL.modifiedAt ?? "")}
                            rightTitle={"Definition - " + formatdate(compareR.modifiedAt ?? "")}
                            splitView={true}
                            showDiffOnly={false}
                            compareMethod={DiffMethod.WORDS} />
                        <br />
                        <ReactDiffViewer
                            oldValue={compareL.comment ?? ""}
                            newValue={compareR.comment ?? ""}
                            leftTitle={"Comment - " + formatdate(compareL.modifiedAt ?? "")}
                            rightTitle={"Comment - " + formatdate(compareR.modifiedAt ?? "")}
                            splitView={true}
                            showDiffOnly={false} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOpenDialogCompare(false) }}>Cancel</Button>
                </DialogActions>
            </Dialog>

            <UpdateStatusMultiple
                onClose={() => { setOpenDialogEditMultiple(false) }}
                handleUpdateStatusMultiple={HandleUpdateStatusMultiple}
                openDialogEditMultiple={openDialogEditMultiple}
            />
        </div>
    );
}

export default Rules;